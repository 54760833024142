"use strict";

require("core-js/modules/es6.object.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isString(value) {
  return typeof value === 'string';
}

exports["default"] = isString;