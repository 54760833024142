import SetTopicAndQuestion from "../../../../../../components/SetTopicAndQuestion";
import Question from "../../../../../../components/Question";
import { Image, Appear, Notes, Invert, SplitRight, Horizontal, FullScreenCode } from 'mdx-deck';
import React from 'react';
export default {
  SetTopicAndQuestion: SetTopicAndQuestion,
  Question: Question,
  Image: Image,
  Appear: Appear,
  Notes: Notes,
  Invert: Invert,
  SplitRight: SplitRight,
  Horizontal: Horizontal,
  FullScreenCode: FullScreenCode,
  React: React
};