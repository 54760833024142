"use strict";

require("core-js/modules/es6.object.define-property");

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isObject(value) {
  return value !== null && typeof value === 'object';
}

exports["default"] = isObject;